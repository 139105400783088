import { render, staticRenderFns } from "./AssignRole.vue?vue&type=template&id=266b7a0d&scoped=true&"
import script from "./AssignRole.vue?vue&type=script&lang=js&"
export * from "./AssignRole.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "266b7a0d",
  null
  
)

export default component.exports